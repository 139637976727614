<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-09-01 13:04 -->
<!-- Description:  -->
<template>
  <div class="row">
    <div class="col">
      <label for="">Fecha</label>
      <input type="date" class="form-control" v-model="date" />
    </div>
    <div class="col">
      <label for="">Entidad</label>
      <SelectEntity v-model="entity_id"></SelectEntity>
    </div>
    <div class="col">
      <a :href="reportUrl" class="btn btn-danger" type="button">
        <i class="fas fa-file-pdf"></i> Generar reporte
      </a>
    </div>
  </div>
</template>
<script>
import SelectEntity from "src/lt/admin-module/entitys/Select.vue";
// script content
export default {
  name: "ReportService",
  components: {
    SelectEntity,
  },

  // directives

  props: {
    //
  },

  data: () => ({
    date: null,
    entity_id: null,
  }),

  computed: {
    reportUrl() {
      return (
        process.env.VUE_APP_API_URL +
        `service/report/client140/service-month?date=${this.date}&entity_id=${this.entity_id}`
      );
    },
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    //
    //
    //
  },
};
</script>
<style scoped></style>
