<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2023-09-01 13:04 -->
<!-- Description:  -->
<template>
  <div>
    <h4>Reporte de servicios</h4>
    <ReportService></ReportService>
  </div>
</template>
<script>
import ReportService from "./ReportService.vue";
// script content
export default {
  name: "ReportPage",
  components: {
    ReportService
  },

  // directives

  props: {
    //
  },

  data: () => ({
    //
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    //
    //
    //
  }
};
</script>
<style scoped></style>

